<template>
  <div class="container-fluid">
    <b-jumbotron class="mt-4">
        <template #header>Istoric tranzacții</template>
    </b-jumbotron>

  </div>
</template>

<script>
import config from '@/config'

export default {
  name: 'History',
  mounted () {
    this.getData()
  },
  data () {
    return {
      data: [],
      selected: [],
      items: [
        { Numar_soferi: 40, Comisioane: 245.32, Suma_catre_soferi: 3990.56 }
      ]
    }
  },
  computed: {
    apiUrl () {
      return config.baseApiUrl + '/api/users?role=driver'
    }
  },
  methods: {
    formatLabel (driver) {
      return driver.name + ' -- 289,00 RON'
    },
    getData () {
      this.$axios.get(this.apiUrl)
        .then((response) => {
          this.data = response.data.data
        })
    }
  }
}
</script>

<style scoped>
  .table tr th,
  .table tr td {
    vertical-align: middle;
  }
</style>
